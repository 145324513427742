import * as React from 'react'
import styled from 'styled-components'
import { breakpointLg, breakpointMd} from '../design/responsive/breakpoint'
import { marginBlock, marginBlockMobile , paragrapMargin} from '../design/spacing/margin'
const TestimonialBlock = styled.section`
   & + .title-block {
    margin-top: -${paragrapMargin};
   }
    &.horizontal-style{
        .container {
            max-width: 820px;
           
        }
        & + .prefooter {
            margin-top: 6rem;
        }
        .testimonial-block__item{
            margin-bottom:  ${paragrapMargin}
        }
    }
`
const TestimonialItem = styled.div`
    margin-bottom: ${paragrapMargin}
`
const ImageContainer = styled.div`
    flex-shrink: 0;
    margin-right: 2rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    > img{
        width: 100%;
        border-radius: 50%;
    }
    @media (max-width: ${breakpointMd}){
        margin-bottom: ${paragrapMargin}
    }
`
const Description = styled.div`
    margin-bottom: ${paragrapMargin};
    min-height: 190px;
    *:last-child{
        margin-bottom: 0;
    }
`
const Name = styled.div`
    font-weight: bold;
`
const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const Content = styled.div`
    height: 100%;
    flex-shrink: 1;
    width: 100%;
    padding: 4rem 2rem;
    border-radius: 16px;
    @media (max-width: ${breakpointMd}){
        display: block !important;
    }
`


const Testimonial = (props: any) => {
    const data = props.data;
    console.log('data Testimonial: ', data);
    const className = props.className ?  props.className : ''
    const typeVertical = props.vertical != null ?  props.vertical : true
    return (
        <>
            <TestimonialBlock className={'tpl-block testimonial-block p-0  ' + props.background + ' ' + className  + ' ' + `${typeVertical ? 'vertical-style' : 'horizontal-style'}`}  >
                <div className='container'>
                    <div className="row testimonial-block__row">
                        {
                            data.testimonial.map((dt: any)=>(

                                <TestimonialItem
                                    className={typeVertical ? 'col-lg-6 testimonial-block__item ': 'col-lg-12 testimonial-block__item '}
                                    key={dt.id}>
                                    {/*bgColor: 4 options: bg-blue-bale, bg-chalk, bg-blue, bg-yellow,*/}
                                    <Content className={ dt.bgColor +"  d-md-flex justify-content-between"}>
                                        <ImageContainer>
                                            <img src={dt.image} alt=''/>
                                        </ImageContainer>
                                        <Info>
                                            <Description dangerouslySetInnerHTML={{__html: dt.content}} />
                                            <Name>
                                                {dt.author}
                                                {
                                                    dt.address ? (
                                                    <span>, {dt.address}</span>
                                                    ) : null
                                                }   
                                                 {
                                                    dt.position ? (

                                                    <div>{dt.position}</div>
                                                    ) : null
                                                }   
                                            </Name>
                                        </Info>
                                    </Content>
                                </TestimonialItem>
                            ))
                        }

                    </div>
                </div>
            </TestimonialBlock> 
        </>
    )
}

export default Testimonial
